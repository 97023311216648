import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthService } from './services/rest-api/auth.service';
import { UserService } from './services/rest-api/user.service';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library, IconPack } from '@fortawesome/fontawesome-svg-core'

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { GeolocationService } from './services/rest-api/geolocation.service';
import { VagasService } from './services/rest-api/vagas.service';
import { FormErrorService } from './_shared/components/form/form-error.service';
import { FileService } from './services/rest-api/file.service';
import { CepService } from './services/rest-api/cep.service';
import { FaqService } from './services/rest-api/faq.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ChatService } from './services/rest-api/chat.service';
import { AlertaEmail } from './services/rest-api/alertaEmail.service';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [
  
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    [
      { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    ],
    AuthService,
    UserService,
    NgxDatatableModule,
    Geolocation,    
    NativeGeocoder,
    InAppBrowser,
    GeolocationService,
    VagasService,
    FormErrorService,
    FileService,
    CepService,
    FaqService,
    ChatService,
    AlertaEmail
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(library: FaIconLibrary) { 
		library.addIconPacks(fas as IconPack, fab as IconPack, far as IconPack);
	}
}