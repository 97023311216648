import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { ModalConfirmModule } from '../_shared/components/modal/modal-confirm/modal-confirm.module';
import { ModalGenericModule } from '../_shared/components/modal/modal-generic/modal-generic.module';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<any>();
  ionToast: HTMLIonToastElement;
  duration = 2000;

  constructor(
    private toastController: ToastController,
    private modalController: ModalController,
  ) {
  }

  async toast(message: string, color: string = '', duration = this.duration) {
    this.ionToast = await this.toastController.create({
      message,
      color,
      duration,
      translucent: true,
      // position: 'top',
    });
    this.ionToast.present().then();
  }

  toastDanger(message: string, duration?: number) {
    this.toast(message, 'danger', duration).then();
  }

  toastSuccess(message: string, duration?: number) {
    this.toast(message, 'success', duration).then();
  }

  async toastWithOptions() {
    this.ionToast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            //console.log('Favorite clicked');
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      ]
    });
    this.ionToast.present().then();
  }

  async modalConfirm(title: string, text: string): Promise<boolean> {
    const modal = await this.modalController.create({
      component: ModalConfirmModule,
      cssClass: 'modal-confirm',
      componentProps: { title, text }
    });
    await modal.present();
    const data = await modal.onWillDismiss();
    return data.data as boolean;
  }

  async modalGeneric(title: string, text: string): Promise<boolean> {
    const modal = await this.modalController.create({
      component: ModalGenericModule,
      // cssClass: 'modal-generic',
      componentProps: { title, text }
    });
    await modal.present();
    const data = await modal.onWillDismiss();
    return data.data;
  }

  sendMessage(message: string) {
    this.subject.next(message);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
}
}
