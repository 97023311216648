import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable()
export class FormErrorService {
	private formErrors = {};
	private defaultMessage: string = 'Campo inválido';

	// return list of error messages
	private validationMessages(paramError) {
		const messages = {
			required: `Campo requerido`,
			email: `Email inválido`,
			notEqual: `Confirmação diferente`,
			minlength: `O número mínimo de caracteres é ${paramError.requiredLength}`,
			maxlength: `O número máximo permitido de caracteres é ${paramError.requiredLength}`,
			pattern: `Campo inválido`,

			//novos padroes de mensagem
			invalid: 'Campo inválido',
			invalidCharacters: 'Caracteres inválidos',
			passwordMatchValidator: 'Senhas não conferem',
		};
		return messages;
	}

	// Validate form instance
	public validateForm(formGroupToValidate: FormGroup, checkDirty?: boolean) {

		Object.keys(formGroupToValidate.controls).forEach(item => {
			const formControl = formGroupToValidate.get(item);

			//recursividade se um formulario tem um objeto com mais valores
			if (formControl instanceof FormGroup) {
				this.validateForm(formControl, checkDirty);
			}
			else {
				if (formControl && !formControl.valid) {
					if (!checkDirty) {
						formControl.markAsDirty();
						formControl.markAsTouched();
					}
					if (formControl.dirty || formControl.touched) {
						let messageLast = '';
						for (const key in formControl.errors) {
							const messages = this.validationMessages(formControl.getError(key));
							const messErr = (typeof formControl.getError(key) === 'string') ? formControl.getError(key) : messages[key];
							messageLast = messageLast ? (messageLast + '. ') : messageLast;
							messageLast += messErr;
						}
						this.formErrors[item] = messageLast || this.defaultMessage;
					}
				}
			}
		});

		return this.formErrors;
    }
}