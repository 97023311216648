import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from './generics/generic.service';
import { FileProgressModel } from '../../models/file-progress.model';

@Injectable()
export class FileService extends GenericService<any> {
  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  uploadImagem(fileP: FileProgressModel): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileP.file);
    // return this.httpClient.post<any>(`https://file.io/${ '' }`, formData, { reportProgress: true, observe: 'events' });
    return this.httpClient.post<any>( this.api('uploadImagem.php'), formData);
  }

  uploadImagemDocumento(fileP: FileProgressModel): Observable<any> {
    //console.log(fileP);
    const formData = new FormData();
    formData.append('file', fileP.file);
    // return this.httpClient.post<any>(`https://file.io/${ '' }`, formData, { reportProgress: true, observe: 'events' });
    return this.httpClient.post<any>( this.api('uploadImagemDocumento.php'), formData);
  }
}
