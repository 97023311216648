import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/rest-api/auth.service';

var listaIgnorar = [
	'uploadImagem.php',
	'uploadImagemDocumento.php',
]

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {		

		var ignorar = false;
		listaIgnorar.forEach(element => {
			if(request.url.endsWith(element)){
				ignorar = true;
				console.log("Ignorando headers");
			}
		});
		

		const token = this.authService.getToken();

		if (token && !ignorar) {
			console.log("Vai clonar");
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
					'Access-Control-Allow-Origin': '*',
					IsMobile: 'true',
					'Content-Type': 'application/json',
					Accept: 'application/json;charset=UTF-8',
				},
			});
		}
    
		return next.handle(request);
	}
}
