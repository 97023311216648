import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class VagasService extends GenericService<any> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  // busca de acordo com o token
  getAllGeocode(latitude: string, longitude: string, zoom: string): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVagaGeocode.php?latitude=' + latitude + '&longitude=' + longitude + '&zoom=' + zoom)}`);
  }

  getAllHome(latitude: string, longitude: string): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVagaHome.php?latitude=' + latitude + '&longitude=' + longitude)}`);
  }

  getAllMotorista(): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVagaMotorista.php')}`);
  }

  getAllProprietario(): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVagaProprietario.php')}`);
  }

  getAllInteressadoProprietario(codVaga: string): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVagaInteressado.php?codVaga=' + codVaga)}`);
  }

  get(id: string): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVaga.php?id=' + id)}`);
  }

  getReserva(id: string): Observable<any> {
    return this.httpClient.get(`${this.api('/listarVagaReservada.php?id=' + id)}`);
  }

  inserir(params): Observable<any> {
    return this.httpClient.post(`${this.api('cadastrarVaga.php')}`, params);
  }

  editar(params): Observable<any> {
    return this.httpClient.post(`${this.api('editarVaga.php')}`, params);
  }

  editarEstado(codVaga, codEstadoVaga): Observable<any> {
    let params: any = {
      codVaga: codVaga,
      codEstadoVaga: codEstadoVaga
    };
    return this.httpClient.post(`${this.api('editarVagaEstado.php')}`, params);
  }

  editarEstadoVagaInteressado(codVagaInteressado, codInteressadoVagaStatus): Observable<any> {
    let params: any = {
      codVagaInteressado: codVagaInteressado,
      codInteressadoVagaStatus: codInteressadoVagaStatus
    };
    return this.httpClient.post(`${this.api('editarVagaInteressado.php')}`, params);
  }

  pagar(card_hash: string, codVaga: string, placa: string): Observable<any> {
    let params: any = {
      card_hash: card_hash,
      codVaga: codVaga,
      placa: placa
    };
    return this.httpClient.post(`${this.api('cadastrarPagamentoPlano.php')}`, params);
  }

  pagarBoleto(codVaga: string, placa: string): Observable<any> {
    let params: any = {
      boleto: 1,
      codVaga: codVaga,
      placa: placa
    };
    return this.httpClient.post(`${this.api('cadastrarPagamentoPlano.php')}`, params);
  }

  reservar(niceId): Observable<any> {
    let params: any = {
      niceId: niceId

    };
    return this.httpClient.post(`${this.api('cadastrarVagaInteressado.php')}`, params);
  }

  excluirVagaMotorista(codVaga): Observable<any> {
    let params: any = {
      codVaga: codVaga

    };
    return this.httpClient.post(`${this.api('excluirVagaMotorista.php')}`, params);
  }

  excluirVagaInteressado(codVaga): Observable<any> {
    let params: any = {
      codVaga: codVaga

    };
    return this.httpClient.post(`${this.api('excluirVagaInteressado.php')}`, params);
  }
}
