import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class LoadingService {

  private ionLoading: HTMLIonLoadingElement;
  private show = false;

  constructor(public loadingController: LoadingController) {
  }

  async loading() {
    if (this.show) {
      return;
    }
    this.ionLoading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Carregando...',
      duration: 5000
    });
    await this.ionLoading.present();
  }

  async loadingWithOptions() {
    this.ionLoading = await this.loadingController.create({
      spinner: null,
      duration: 5000,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true
    });
    await this.ionLoading.present();
  }

  start() {
    this.loading().then();
    this.show = true;
  }

  stop() {
    this.ionLoading?.dismiss().then();
    this.show = false;
  }
}
