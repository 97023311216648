//api: 'https://backoffice.zuli.isaias.eti.br/json',
//site_url: 'https://zuli.isaias.eti.br',
//encryption_key: 'ek_test_Xjo9B2d9QpyW6xoRj18uVndJxS0Zqw'

//api: 'https://backoffice.homolog.zulipark.com/json',
//site_url: 'https://homolog.zulipark.com',
//encryption_key: 'ek_live_ClAH8JhkNawtMj0H7Ze6Ni2xhA4f6B'

//api: 'https://backoffice.zulipark.com/json',
//site_url: 'https://zulipark.com',
//encryption_key: 'ek_live_ClAH8JhkNawtMj0H7Ze6Ni2xhA4f6B'

export const environment = {
  production: false,
  api: 'https://backoffice.zulipark.com/json',
  site_url: 'https://zulipark.com',
  pagar_me: {
    api: 'https://api.pagar.me/1/',
    encryption_key: 'ek_live_ClAH8JhkNawtMj0H7Ze6Ni2xhA4f6B',    
  }
};
