import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class GeolocationService extends GenericService<any> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  forwardGeocode(endereco: string): Observable<any> {
    return this.httpClient.post(`${ this.api('forwardGeocode.php') }`, { endereco });
  }

}
