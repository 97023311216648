import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
  ) {
  }

  ngOnInit() {
  }

  async close(isOk: boolean = false) {
    await this.modalController.dismiss(isOk);
  }

}
