import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MessageService } from './services/message.service';
import { UserService } from './services/rest-api/user.service';
import { AuthService } from './services/rest-api/auth.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    { title: 'Mapa', url: 'mapa' },
    { title: 'Sobre a Zuli', url: 'sobre-a-zuli' },
    { title: 'Como Funciona', url: 'como-funciona' },
    { title: 'Entre em Contato', url: 'contato' },
    { title: 'Entre', url: 'area-interna/0' },
    { title: 'Anunciar Vaga', url: 'area-interna/0' },
  ];

  constructor(
    private platform: Platform,
    private messageService: MessageService,
    private authService: AuthService,
    private userService: UserService,
    public navCtrl: NavController,
    private titleService: Title,
    private meta: Meta,
    private router: Router
  ) {

    var subscription: Subscription;
    subscription = this.messageService.getMessage().subscribe(message => {
      if (message == 'UNAUTHORIZED') {
        //this.userService.logout().subscribe();
        this.authService.logout();
        //console.log(this.router.url);
        if(this.router.url.includes("login")) this.navCtrl.navigateRoot(this.router.url);
        else this.navCtrl.navigateRoot("login"+this.router.url);
      }
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }
}
