import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class ChatService extends GenericService<any> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  cadastrar(params): Observable<any> {
    return this.httpClient.post(`${this.api('cadastrarChat.php')}`, params);
  }
}
