import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generics/generic.service';
import { of } from 'rxjs';

@Injectable()
export class AuthService extends GenericService<any> {
  private static currentUser: any;
  private static token: string;

  constructor(
    protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  login(login: string, password: string, pushIDAndroid: string = '', pushIDIOS: string = '', latitude: string = '', longitude: string = ''): Observable<any> {
    let params: any = {
      login: login,
      senha: password,
      pushIDAndroid: pushIDAndroid,
      pushIDIOS: pushIDIOS,
      latitude: latitude,
      longitude: longitude
    };
    return this.httpClient.post(this.api('/login.php'), params);
  }

  loginOauth(servico: string, userId: string, accessToken: string, pushIDAndroid: string = '', pushIDIOS: string = '', latitude: string = '', longitude: string = '', nome: string = ''): Observable<any> {
    let params: any = {
      servico: servico,
      userId: userId,
      accessToken: accessToken,
      pushIDAndroid: pushIDAndroid,
      pushIDIOS: pushIDIOS,
      latitude: latitude,
      longitude: longitude,
      nome: nome
    };
    return this.httpClient.post(this.api('/loginOauth.php'), params);
  }

  logError(error: string) {
    let params: any = {
      error: error
    };
    return this.httpClient.post(this.api('/logError.php'), params);
  }

  logout(): void {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('currentUser');
    AuthService.currentUser = null;
    AuthService.token = null;
  }

  loginForget(login: string): Observable<any> {
    return this.httpClient.post(this.api('/esqueceuSenha.php'), { login: login });
  }

  setToken(token) {
    if (token) {
      window.localStorage.setItem('token', JSON.stringify(token));
      AuthService.token = token;
    } else {
      AuthService.currentUser = null;
    }
  }

  getToken(): string {
    if (!AuthService.currentUser) {
      const token = JSON.parse(window.localStorage.getItem('token'));
      if (token) {
        return token;
      }
      return null;
    }
    return AuthService.token;
  }

  setCurrentUser(user): any {
    user.token = JSON.parse(window.localStorage.getItem('token'));
    window.localStorage.setItem('currentUser', JSON.stringify(user));
    AuthService.currentUser = user;
    return AuthService.currentUser;
  }

  get getCurrentUser(): any {
    if (!AuthService.currentUser) {
      const current = JSON.parse(window.localStorage.getItem('currentUser'));
      return !current ? null : current;
    }
    return AuthService.currentUser;
  }

  containsPermision(text: string) {
    return this.getCurrentUser && this.getCurrentUser.papel && this.getCurrentUser.papel.includes(text) ? true : false;
  }

  /**
   * se usuario autenticado
   * @param {boolean} isNotRedirectPath - usado para não redirecionar quando usuário não está logado
   * @returns {boolean}
   */
  tokenNaoEspirado(latitude: string = '', longitude: string = '', vip = ''): Observable<any> {
    try {
      return this.httpClient.get(`${ this.api('/verificarToken.php?latitude=' + latitude + '&longitude=' + longitude + '&vip=' + vip) }`);
    } catch (error) {
      return of(false);
    }
  }
}
