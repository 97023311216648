import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { UserModel } from '../../models/user.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserService extends GenericService<UserModel> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  updateProfile(object: any): Observable<any> {
    return this.httpClient.post(`${ this.api('editarUsuario.php') }`, this.removeBlankVariables(object));
  }

  setPassword(passwordOld: string, passwordNew: string, passwordConfirm: string): Observable<any> {
    return this.httpClient.post(`${ this.api('/trocaSenha.php') }`, {
      login: passwordOld,
      senha: passwordNew,
      senhaNova: passwordConfirm
    });
  }

  recoverPassword(login: string): Observable<any> {
    let params: any = {
      telefoneEmail: login
    };
    return this.httpClient.post(`${this.api('esqueceuSenha.php')}`, params);
  }

  recoverPassword2(params: any): Observable<any> {
    return this.httpClient.post(`${this.api('esqueceuSenha2.php')}`, params);
  }

  logout(): Observable<any> {
    return this.httpClient.get(`${ this.api('/logout.php') }`);
  }

  getBigIdQuestion(object: any): Observable<any> {
    return this.httpClient.post(`${ this.api('autoCadastroQuestionarioBigId.php') }`, this.removeBlankVariables(object));
  }

  insert(object: any): Observable<any> {
    return this.httpClient.post(`${ this.api('autoCadastro.php') }`, this.removeBlankVariables(object));
  }

  update(object: any): Observable<any> {
    return this.httpClient.post(`${ this.api('editarUsuario.php') }`, this.removeBlankVariables(object));
  }

  deletePhoto(numeroFoto: number): Observable<any> {
    return this.httpClient.post(`${ this.api('excluirFoto.php') }`, { numeroFoto });
  }

  delete(): Observable<any> {
    return this.httpClient.get(`${ this.api('excluirUsuario.php') }`);
  }

  setPosition(latitude: number, longitude: number) {
    window.localStorage.setItem('user_position_latitude', latitude.toString());
    window.localStorage.setItem('user_position_longitude', longitude.toString());
  }

  getPosition(): any {
    const position = {
      latitude: window.localStorage.getItem('user_position_latitude'),
      longitude: window.localStorage.getItem('user_position_longitude')
    };
    return position;
  }

  getPositionByIp(): Observable<any> {
    return this.httpClient.get(`https://ipapi.co/json/`);
  }

  setPush(android: string, ios: string) {
    window.localStorage.setItem('user_push_android', android);
    window.localStorage.setItem('user_push_ios', ios);
  }

  getPush(): any {
    const push = {
      android: window.localStorage.getItem('user_push_android'),
      ios: window.localStorage.getItem('user_push_ios')
    };
    return push;
  }

  getConta():Observable<any> {
    return this.httpClient.get(`${ this.api('/listarConta.php') }`);
  }

  remove():Observable<any> {
    return this.httpClient.get(`${ this.api('/excluirUsuario.php') }`);
  }
}
