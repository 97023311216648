import { HttpClient } from '@angular/common/http';
import { GenericHttpServiceBase } from './generic-http-service-base';
import { FormGroup } from '@angular/forms';

export class GenericService<T> extends GenericHttpServiceBase {

  constructor(protected httpClient: HttpClient) {
    super();
  }

  /**
   * remove variaveis e valores em branco de um modelo
   * @param obj
   * @protected
   */
  protected removeBlankVariables(obj: T) {
    const objTmp = {};
    Object.keys(obj).map((value) => {
      const name = value.replace('_', '');
      objTmp[name] = obj[name];
    });
    return objTmp;
  }

  public pastValues(formGroup: FormGroup, obj: T) {
    Object.keys(formGroup.controls).forEach(item => {
      const formControl = formGroup.get(item);
      if (formControl instanceof FormGroup) {
        this.pastValues(formControl, obj);
      } else {
      }
    });
  }
}
