import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class CepService extends GenericService<any> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  get(cep: string): Observable<any> {
    return this.httpClient.get(`${ this.api('/consultaCep.php?cep='+cep) }`);
  }

  getGoogleStreetView(logradouro,numero,bairro,cidade,estado): Observable<any> {
    return this.httpClient.get(`${ this.api('/consultaImagemStreetView.php?logradouro='+logradouro+'&numero='+numero+'&bairro='+bairro+'&cidade='+cidade+'&estado='+estado) }`);
  }
}
