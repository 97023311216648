import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'contato',
    loadChildren: () => import('./pages/contato/contato.module').then( m => m.ContatoPageModule)
  },
  {
    path: 'sobre-a-zuli',
    loadChildren: () => import('./pages/sobre-a-zuli/sobre-a-zuli.module').then( m => m.SobreAZuliPageModule)
  },
  {
    path: 'como-funciona',
    loadChildren: () => import('./pages/como-funciona/como-funciona.module').then( m => m.ComoFuncionaPageModule)
  },
  {
    path: 'area-interna',
    loadChildren: () => import('./pages/area-interna/area-interna.module').then( m => m.AreaInternaPageModule)
  },
  {
    path: 'area-interna/:codigoInterno',
    loadChildren: () => import('./pages/area-interna/area-interna.module').then( m => m.AreaInternaPageModule)
  },
  {
    path: 'area-interna/:codigoInterno/:codInteressadoVaga',
    loadChildren: () => import('./pages/area-interna/area-interna.module').then( m => m.AreaInternaPageModule)
  },  
  {
    path: 'cadastrar',
    loadChildren: () => import('./pages/area-interna/cadastrar/cadastrar.module').then( m => m.CadastrarPageModule)
  },
    {
    path: 'login',
    loadChildren: () => import('./pages/area-interna/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'login/:route',
    loadChildren: () => import('./pages/area-interna/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'login/:route/:item',
    loadChildren: () => import('./pages/area-interna/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'login/:route/:item/:subitem',
    loadChildren: () => import('./pages/area-interna/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'confirmar-reserva',
    loadChildren: () => import('./pages/area-interna/reservar/confirmar-reserva/confirmar-reserva.module').then( m => m.ConfirmarReservaPageModule)
  },
  {
    path: 'confirmar-reserva/:id',
    loadChildren: () => import('./pages/area-interna/reservar/confirmar-reserva/confirmar-reserva.module').then( m => m.ConfirmarReservaPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./pages/mapa/mapa.module').then( m => m.MapaPageModule)
  },{
    path: 'mapa/:id',
    loadChildren: () => import('./pages/mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'vaga/:id',
    loadChildren: () => import('./pages/vaga/vaga.module').then( m => m.VagaPageModule)
  },
  {
    path: 'reservar/:id',
    loadChildren: () => import('./pages/area-interna/reservar/reservar.module').then( m => m.ReservarPageModule)
  },{
    path: ':cidade',
    redirectTo: 'mapa/:cidade',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
