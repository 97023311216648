// Autor: Isaias José Ramos de Oliveira GitHub: @ijro

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class AlertaEmail extends GenericService<any>{
    constructor(
        protected httpClient: HttpClient
    ) {
        super(httpClient);
    }

    cadastrar(text,email): Observable<any> {
        var params = {
            text:text,
            email:email
        }
        return this.httpClient.post(`${this.api('cadastrarAlertaEmail.php')}`, params);
    }
}
