// Autor: Isaias José Ramos de Oliveira GitHub: @ijro

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generics/generic.service';
import { Observable } from 'rxjs';

@Injectable()
export class FaqService extends GenericService<any>{
    constructor(
        protected httpClient: HttpClient
    ) {
        super(httpClient);
    }

    getAll():Observable<any> {
        return this.httpClient.get(`${this.api('listarFaq.php')}`);
    }
}
