import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss'],
})
export class ModalGenericComponent {

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
  ) {
  }

  async close(isOk: boolean = false) {
    await this.modalController.dismiss(isOk);
  }

}
