import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessageService } from '../services/message.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private whiteList = [
		
	];

	constructor(private messageService: MessageService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const containsInWhiteList = this.whiteList.includes(request.url);

		return next.handle(request).pipe(
			tap(
				_ => { },
				err => {
					if (containsInWhiteList) {
						return;
					}

					switch (err.status) {
						case 0:
							this.handle0();
							break;
						case 401:
							this.messageService.sendMessage('UNAUTHORIZED');
							break;
						default:
							this.genericError(err);
							break;
					}
				}
			)
		);
	}

	private handle0() {
		this.messageService.toastDanger('Sem conexão com o servidor Zuli Park. :(');
	}

	private genericError(errorResponse: HttpErrorResponse) {
		if (errorResponse?.error?.title === 'Fail') {
			this.messageService.toastDanger(errorResponse.error.message);
			return;
		} else if (errorResponse?.error?.cupom?.mensagem) {
			this.messageService.toastDanger(errorResponse.error.cupom.mensagem);
			return;
		}
		this.messageService.toastDanger(`Erro ${errorResponse.status} - Aconteceu algo inesperado. :(`);
	}
}
